import Grid from "@mui/material/Grid";
import SignInWithGoogle from "./auth/SignInWithGoogle";
import {useSessionData} from "./auth/SessionDataProvider";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import RequireAdmin from "./auth/RequireAdmin";
import axios from "axios";

export default function Header() {
    const { sessionData } = useSessionData();

    const LogOut = ()=>{
        localStorage.removeItem('email');
        localStorage.removeItem('userName');
        localStorage.removeItem('rights');
        localStorage.clear();
        axios.post('/api/user/close_session')
            .then(()=>{
                window.location.href = '/';
            })
    }

    return (
        <header>
            <Grid container sx={{ p: 2 }}>
                <Grid item xs={3}>
                    <h3>Kerítés tervező</h3>
                </Grid>
                <Grid item xs={6}>
                    <RequireAdmin>
                        <Button variant="contained" color="primary" component={Link} to={"/orders"} sx={{ ml: 2}}>
                            Rendelések
                        </Button>
                        <Button variant="contained" color="primary" component={Link} to={"/addElement"} sx={{ ml: 2}}>
                            Elem feltöltés
                        </Button>
                        <Button variant="contained" color="primary" component={Link} to={"/elements"} sx={{ ml: 2}}>
                            Elem lista
                        </Button>
                        <Button variant="contained" color="primary" component={Link} to={"/addColor"} sx={{ ml: 2}}>
                            Szín hozzáadás
                        </Button>
                        <Button variant="contained" color="primary" component={Link} to={"/addColumn"} sx={{ ml: 2}}>
                            Oszlop hozzáadás
                        </Button>
                    </RequireAdmin>
                </Grid>
                <Grid item xs={3}>
                    { sessionData.userId === 0 ?
                        <SignInWithGoogle/> :
                        (
                        <>
                            Bejelentkezve: {sessionData.username}
                            <Button variant="contained" color="primary" onClick={LogOut} sx={{ ml: 2}}>
                                Kijelentkezés
                            </Button>
                        </>
                        )}
                </Grid>
            </Grid>
        </header>
    );
}
