import { createContext, useContext, useEffect, useState } from 'react';

const SessionDataContext = createContext();

export function SessionDataProvider({ children }) {
    const [sessionData, setSessionData] = useState({
        userId: 0,
        rights: 0,
        logged: 0,
        isLogged: false,
        isUserAdmin: false,
        isUserTeacher: false,
        username: '',
        email: '',
    });

    const isOnPath = (path) => {
        return window.location.pathname.includes(path);
    };

    useEffect(() => {
        setSessionData((prevData) => ({
            ...prevData,
            isUserAdmin: prevData.rights === 5,
            isLogged: prevData.logged === 1,
        }));
    }, [sessionData.userId]);

    return (
        <SessionDataContext.Provider
            value={{
                sessionData,
                setSessionData,
                isOnPath,
            }}
        >
            {children}
        </SessionDataContext.Provider>
    );
}

export function useSessionData() {
    const context = useContext(SessionDataContext);
    if (!context) {
        throw new Error('useSessionData must be used within a SessionDataProvider');
    }
    return context;
}
