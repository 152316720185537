import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import { useDrop } from "react-dnd";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const useColumnDrop = (data, setData, columnIndex) => {
    const droppedItems = data.columns[columnIndex]; // Reference directly to the columns in data object

    const handleDrop = (item) => {
        const updatedColumns = [...data.columns]; // Make a copy of columns array

        // Ensure droppedItems is an array and initialize if it's undefined
        const droppedItems = Array.isArray(updatedColumns[columnIndex]) ? [...updatedColumns[columnIndex]] : [];

        const updatedDroppedItems = [...droppedItems, item];
        updatedColumns[columnIndex] = updatedDroppedItems;

        setData({
            ...data,
            columns: updatedColumns,
        });
    };

    const handleRemove = (index) => {
        const updatedItems = [...droppedItems];
        updatedItems.splice(index, 1);

        const updatedColumns = [...data.columns]; // Make a copy of columns array
        updatedColumns[columnIndex] = updatedItems;

        setData({
            ...data,
            columns: updatedColumns,
        });
    };

    const [{ canDrop, isOver }, drop] = useDrop({
        accept: "element",
        drop: (item) => handleDrop(item),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    return { droppedItems, drop, handleRemove };
};

export default function FenceBuilder({ data, setData }) {
    const totalHeight = data.planner.columnTop ? data.planner.height + 23 : data.planner.height;
    const totalWidth = data.planner.width ? data.planner.width : 200;

    return (
        <Grid container sx={{ height: totalHeight + 'px', width: totalWidth + 'px', overflow: 'hidden' }}>
            {data.columns.map((column, columnIndex) => (
                <React.Fragment key={columnIndex}>
                    <Grid container
                          sx={{
                                  width: data.planner.columnWidth + 'px',
                              }}>
                        {data.planner.columnTop && (
                                <img src={data.planner.columnTop} alt="Column Top Image" width={"23px"} height={"23px"} />
                        )}
                        <Grid
                            item
                            xs={12}
                            sx={{
                                height: data.planner.height + 'px',
                                width: data.planner.columnWidth + 'px',
                                backgroundColor: data.planner.columnColor,
                                backgroundImage: `url(${data.planner.columnImage})`,
                                backgroundRepeat: 'repeat',
                                backgroundSize: '100% auto',
                            }}
                        >
                        </Grid>
                    </Grid>
                    <Grid key={columnIndex} item >
                        <ColumnDropZone data={data} setData={setData} columnIndex={columnIndex} currentColumn={column} />
                    </Grid>
                </React.Fragment>
            ))}
        </Grid>
    );
}

// Component responsible for rendering the drop zone in each column
function ColumnDropZone({ data, setData, columnIndex, currentColumn }) {
    const [hoveredImage, setHoveredImage] = useState(null); // State to track hovered image index
    const { droppedItems, handleRemove, drop } = useColumnDrop(data, setData, columnIndex);
    const totalHeight = data.planner.columnTop ? data.planner.height + 23 : data.planner.height;

    if (Array.isArray(data.columns)) {
        const columnData = data.columns[columnIndex];
        const totalImageHeight = Array.isArray(columnData)
            ? columnData.map((item) => item.element.height).reduce((totalHeight, currentHeight) => totalHeight + currentHeight, 0)
            : 0;
        const cellWidth = Array.isArray(columnData)
            ? columnData.map((item) => item.element.width || 200) // Map widths, use 0 if width is falsy
                .reduce((maxWidth, currentWidth) => Math.max(maxWidth, currentWidth), 0) // Find the maximum width
            : 200;
        const totalCellWidth = cellWidth === 0 ? 200 : cellWidth;

        const adjustedTotalImageHeight = data.planner.columnTop ? totalImageHeight : totalImageHeight;

        return (
            <div
                style={{
                    width: '100%',
                    minWidth: totalCellWidth + 'px',
                    height: totalHeight + 'px',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column-reverse',
                }}
            >
                <div style={{ position: 'relative', zIndex: '0' }}>
                    {Array.isArray(columnData) && columnData.map((item, index) => (
                        <div
                            key={index}
                            style={{
                                position: 'relative',
                                width: item.element.width + 'px',
                                height: item.element.height + 'px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            onMouseEnter={() => setHoveredImage(index)}
                            onMouseLeave={() => setHoveredImage(null)}
                        >
                            <img
                                src={item.element.image}
                                alt={item.name}
                                style={{ width: '100%', height: item.element.height + 'px' }}
                            />
                            {/*Festés*/}
                            {/*<div*/}
                            {/*    style={{*/}
                            {/*        position: 'absolute',*/}
                            {/*        top: 0,*/}
                            {/*        left: 0,*/}
                            {/*        width: '100%',*/}
                            {/*        height: '100%',*/}
                            {/*        backgroundColor: 'rgba(255, 0, 0, 0.5)', // Change this to your desired color and opacity*/}
                            {/*    }}*/}
                            {/*/>*/}
                            {hoveredImage === index && (
                                <IconButton
                                    onClick={() => handleRemove(index)}
                                    sx={{
                                        position: 'absolute',
                                        top: '0',
                                        right: '0',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <HighlightOffIcon color={"error"} />
                                </IconButton>
                            )}
                        </div>
                    )).reverse()}
                </div>
                <div
                    style={{
                        position: 'relative',
                        width: '100%',
                        height: '50px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                    }}
                >
                </div>
                {totalImageHeight < data.planner.height && (
                    <div
                        ref={drop}
                        style={{
                            width: '99%',
                            minWidth: totalCellWidth + 'px',
                            height: '50px', // Height of the "+" drop zone
                            border: '2px dashed #000',
                            backgroundColor: 'rgba(255, 255, 255, 0.5)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'absolute',
                            bottom: `${adjustedTotalImageHeight}px`, // Position the drop zone at the bottom
                            zIndex: '1',
                        }}
                    >
                        <h2>+</h2>
                    </div>
                )}
            </div>
        );
    } else {
        return null;
    }
}
