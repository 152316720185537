import { useSessionData } from "./SessionDataProvider";

function RequireAdmin({ children })
{
    const { sessionData } = useSessionData();

    if (sessionData.isUserAdmin === true) {
        return children;
    }

    return null;
}

export default RequireAdmin;
