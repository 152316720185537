import React, {useEffect, useState} from "react";
import ImageUpload from "./util/ImageUpload";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import FenceBuilder from "./fenceBuilder";

const imageList = [
    "images/ground/T1.jpg",
    "images/ground/T2.jpg",
    "images/ground/T3.jpg",
    "images/ground/T4.jpg",
    "images/ground/T5.jpg",
    "images/ground/T6.jpg",
    "images/ground/T7.jpg",
    "images/ground/T8.jpg",
];

const RoundedIconButton = styled(IconButton)({
    borderRadius: "50%",
});

export default function Planner({ data, setData }) {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [imageUrl, setImageUrl] = useState(data.image);

    useEffect(() => {
        setImageUrl(data.image instanceof Blob ? URL.createObjectURL(data.image) : data.image);
    }, [data]);

    const switchImage = (index) => {
        setCurrentImageIndex(index);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box position="relative" sx={{ top: 0 }}>
                    {imageUrl && (
                        <>
                            <Box position="absolute" top={0} left={0}>
                                <ImageUpload data={data} setData={setData} />
                            </Box>
                            <img src={imageUrl} alt="main" style={{ width: data.planner.width + 'px' }} />
                            <Box
                                position="absolute"
                                bottom={0}
                                left={0}
                            >
                                <FenceBuilder data={data} setData={setData} />
                            </Box>
                        </>
                    )}
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box position="relative">
                    <img
                        src={imageList[currentImageIndex]}
                        alt="ground"
                        style={{ width: data.planner.width + 'px' }}
                    />
                    <Box
                        position="absolute"
                        bottom={0}
                        left={10}
                        p={1}
                        bgcolor="rgba(255, 255, 255, 0.5)"
                        sx={{ borderRadius: "15px", mb: 2 }}
                    >
                        <Grid container spacing={1}>
                            {imageList.map((image, index) => (
                                <Grid item key={index}>
                                    <RoundedIconButton onClick={() => switchImage(index)}>
                                        <img
                                            src={image}
                                            alt={`image-${index}`}
                                            width="35"
                                            height="35"
                                            style={{ borderRadius: "50%",
                                            border: `3px solid ${
                                                currentImageIndex === index ? "#000" : "#fff"
                                            }`,
                                            }}
                                        />
                                    </RoundedIconButton>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}
