import React, { useState } from 'react';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import axios from "axios";
import InputLabel from '@mui/material/InputLabel';
import { FormControl, MenuItem, Select } from "@mui/material";

export default function CreateColor() {
    const [inputs, setInputs] = useState({
        name: '',
        color: '#ffffff',
        type: 1,
    });

    const options = [
        { value: '1', label: 'Anyagában színezett' },
        { value: '2', label: 'Utólag festett' },
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        for (const [key, value] of Object.entries(inputs)) {
            formData.append(key, value);
        }
        axios.post('/api/create/color', formData)
            .then(function (response) {
                console.log(response);
                if (response.data === 1) {
                    setInputs((prevInputs) => ({
                        ...prevInputs,
                        name: '',
                        color: '#ffffff',
                        type: 1,
                    }));
                }
            });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputs((prevInputs) => ({
            ...prevInputs,
            [name]: value
        }));
    };

    return (
        <>
            <Container>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3, p: 3 }}>
                    <InputLabel id="main-cat-label">
                        <h3>Szín hozzáadás</h3>
                    </InputLabel>
                    <TextField
                        fullWidth
                        id="name"
                        label="Szín neve"
                        name="name"
                        value={inputs.name}
                        onChange={handleChange}
                        sx={{ mt: 3 }}
                    />
                    <TextField
                        fullWidth
                        id="color"
                        label="Színkód (Hex: #FFF)"
                        name="color"
                        type="color"
                        value={inputs.color}
                        onChange={handleChange}
                        sx={{ mt: 3 }}
                        inputProps={{
                            style: {
                                paddingTop: '14px', // Adjust padding to center color picker in the input field
                            },
                        }}
                    />
                    <FormControl fullWidth>
                        <InputLabel id="category-label" sx={{ mt: 3 }}>Kategória</InputLabel>
                        <Select
                            id="type"
                            name="type"
                            labelId="category-label"
                            value={inputs.type}
                            onChange={handleChange}
                            label="Kategória"
                            sx={{ mt: 3 }}
                        >
                            {options.map((option) => (
                                <MenuItem key={option.value} value={option.value}> {option.label} </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Feltöltés
                    </Button>
                </Box>
            </Container>
        </>
    );
}
