import React, {useEffect, useState} from 'react';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import axios from "axios";
import InputLabel from '@mui/material/InputLabel';
import CustomFileInput from "../util/CustomFileInput";
import {FormControl, ListItemIcon, ListSubheader, MenuItem, Select} from "@mui/material";
import Grid from "@mui/material/Grid";

export default function CreateElement() {
    const [inputs, setInputs] = useState({
        name: '',
        height: 50,
        width: 200,
        type: 1,
        color: 0,
    });
    const [imageSrc, setImageSrc] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [colors, setColors] = useState([]);

    const options = [
        { value: '1', label: '205 cm' },
        { value: '2', label: '213 cm' },
        { value: '3', label: '215 cm' },
        { value: '6', label: '200 cm' },
        { value: '4', label: 'Kapu' },
        { value: '5', label: 'Bejáró' },
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        for (const [key, value] of Object.entries(inputs)) {
            formData.append(key, value);
        }
        formData.append('image', imageFile);
        axios.post('/api/create/element', formData)
            .then(function (response) {
                console.log(response);
                if (response.data === 1) {
                    setInputs((prevInputs) => ({
                        ...prevInputs,
                        name: '',
                        height: 50,
                        width: 200,
                        type: 1,
                        color: 0,
                    }));
                    setImageSrc(null);
                    setImageFile(null);
                }
            });
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImageSrc(e.target.result);
            };
            reader.readAsDataURL(file);
            setImageFile(file);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputs((prevInputs) => ({
            ...prevInputs,
            [name]: value
        }));
    };

    useEffect(() => {
        getColors();
    }, []);

    function getColors() {
        axios.get('/api/get/colors')
            .then((result) => {
                if (result.data !== false) {
                    setColors(result.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <>
            <Container>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3, p: 3 }}>
                    <InputLabel id="main-cat-label">
                        <h3>Elem hozzáadás</h3>
                    </InputLabel>
                    <CustomFileInput handleImageChange={handleImageChange} customText={"Töltsön fel vagy húzzon ide képet"} />
                    {imageSrc && <img src={imageSrc} alt="preview" width="200" />}
                    <TextField
                        fullWidth
                        id="name"
                        label="Elem neve"
                        name="name"
                        value={inputs.name}
                        onChange={handleChange}
                        sx={{ mt: 3 }}
                    />
                    <TextField
                        fullWidth
                        id="height"
                        label="Magasság (cm)"
                        name="height"
                        value={inputs.height}
                        type="number"
                        onChange={handleChange}
                        sx={{ mt: 3 }}
                    />
                    <TextField
                        fullWidth
                        id="width"
                        label="Szélesség (cm)"
                        name="width"
                        value={inputs.width}
                        type="number"
                        onChange={handleChange}
                        sx={{ mt: 3 }}
                    />
                    <FormControl fullWidth>
                        <InputLabel id="category-label" sx={{ mt: 3 }}>Kategória</InputLabel>
                        <Select
                            id="type"
                            name="type"
                            labelId="category-label"
                            value={inputs.type}
                            onChange={handleChange}
                            label="Kategória"
                            sx={{ mt: 3 }}
                        >
                            {options.map((option) => (
                                <MenuItem key={option.value} value={option.value}> {option.label} </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Grid>
                        <Select
                            id="color"
                            name="color"
                            value={inputs.color}
                            onChange={handleChange}
                            fullWidth
                            sx={{ mt: 3 }}
                        >
                            <MenuItem key={0} value={0}>
                                <ListItemIcon>
                                    <div
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            backgroundColor: "#414141FF",
                                            marginRight: '2px',
                                            border: '1px solid #ccc',
                                            borderRadius: '50%',
                                        }}
                                    />
                                </ListItemIcon>
                                Natúr betonszín
                            </MenuItem>
                            <ListSubheader key={0} disableSticky>
                                Anyagában színezett
                            </ListSubheader>
                            {colors.map((color, index) => (
                                color.type === 1 &&
                                <MenuItem key={index} value={color.id}>
                                    <ListItemIcon>
                                        <div
                                            style={{
                                                width: '20px',
                                                height: '20px',
                                                backgroundColor: color.color,
                                                marginRight: '2px',
                                                border: '1px solid #ccc',
                                                borderRadius: '50%',
                                            }}
                                        />
                                    </ListItemIcon>
                                    {color.name}
                                </MenuItem>
                            ))}
                            <ListSubheader key={1} disableSticky>
                                Festéssel rendelhető színek
                            </ListSubheader>
                            {colors.map((color, index) => (
                                color.type === 2 &&
                                <MenuItem key={index} value={color.id}>
                                    <ListItemIcon>
                                        <div
                                            style={{
                                                width: '20px',
                                                height: '20px',
                                                backgroundColor: color.color,
                                                marginRight: '2px',
                                                border: '1px solid #ccc',
                                                borderRadius: '50%',
                                            }}
                                        />
                                    </ListItemIcon>
                                    {color.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Feltöltés
                    </Button>
                </Box>
            </Container>
        </>
    );
}
