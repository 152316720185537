import {useEffect} from "react";

export default function GoogleSignInButton()
{
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        script.defer = true;
        script.onload = () => {
            window.google.accounts.id.initialize({
                client_id: "96780112671-9edkj4k2e1oifri4p45ms21j1491om02.apps.googleusercontent.com",
                // client_id: "95343576579-8nu8f2aq10d0lh2vsmosf1pivo9s36v9.apps.googleusercontent.com",
                callback: window.handleCredentialResponse,
            });

            const buttonContainer = document.getElementById('google-button');

        if (buttonContainer) {
            window.google.accounts.id.renderButton(
                buttonContainer,
                {
                    theme: "outline",
                    size: "large",
                    }
            );
        }
        };
        document.body.appendChild(script);
    }, []);

    return (
        <div id="google-button"></div>
    );
}
