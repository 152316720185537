import axios from "axios"
import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import {Link, useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";

export default function ListElements()
    {
        const [data, setData] = useState([]);
        const navigate = useNavigate();

        const columns = [
            { field: 'id', headerName: 'ID', width: 70},
            { field: 'name', headerName: 'Név', width: 250, editable: true},
            { field: 'width', headerName: 'Szélesség:', width: 150, editable: true},
            { field: 'height', headerName: 'Magasság:', width: 150, editable: true},
            {
                field: 'actions',
                headerName: 'Törlés',
                width: 130,
                renderCell: (params) => (
                    <>
                        <Button
                            onClick={() => deleteRow(params.row.id)}
                            style={{ marginRight: "10px" }}
                            variant="contained"
                            color="error"
                        >
                            Törlés
                        </Button>
                    </>
                ),
            },
        ];

        function saveCellChange(params) {
            const formData = new FormData();
            for (const [key, value] of Object.entries(params)) {
                formData.append(key, value);
            }
            axios.post(`/api/edit/element`, formData)
                .then(function (response) {
                    if (response.data  === 1) {
                    }
                });
        }

        const deleteRow = (rowid) => {
            console.log(rowid);
            const formData = new FormData();
            formData.append('id', rowid);
            axios.post('/api/delete/element', formData).then(function (response) {
                getMasterPrompts();
            });
        };

        useEffect(() => {
            getMasterPrompts();
        }, []);

        function getMasterPrompts()
        {
            axios.get('/api/get/elements').then(function (response) {
                setData(response.data);
            });
        }

        return (
        <div style={{ height: '100%', width: '100%' }}>
            <DataGrid
                rows={data}
                columns={columns}
                editMode="cell"
                pageSizeOptions={[5, 10, 100]}
                processRowUpdate={(params) => saveCellChange(params)}
                onProcessRowUpdateError={(params, error) => console.log(error)}
            />
        </div>
        )
    }
