import './App.css';
import Header from "./components/header";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Grid from "@mui/material/Grid";
import axios from "axios";
import Sidebar from "./components/sidebar";
import Planner from "./components/planner";
import { useSessionData } from "./components/auth/SessionDataProvider";
import { useEffect, useState } from "react";
import CreateElement from "./components/create/CreateElement";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Button from "@mui/material/Button";
import RequireAdmin from "./components/auth/RequireAdmin";
import CreateColor from "./components/create/CreateColor";
import ListOrders from "./components/util/ListOrders";
import ListElements from "./components/util/ListElements";
import CreateColumn from "./components/create/CreateColumn";

const initialDataArray = [
    {
        name: "Elöl",
        image: './images/sample/Minta_haz_elol.jpg',
        color: 0,
        planner: {
            width: 1115,
            height: 200,
            columnWidth: 23,
            columnColor: '#414141FF',
            color: 0,
            columnImage: './images/settings/11cm_sima.png',
            columnTop: null,
        },
        columns: Array.from({ length: 5 }, (_, index) => ({
            element: {
                width: 200,
                height: 0,
                // Add other properties as needed
            },
        })),
    },
    {
        name: "Jobb oldal",
        image: './images/sample/Minta_haz_jobb.jpg',
        color: 0,
        planner: {
            width: 1115,
            height: 200,
            columnWidth: 23,
            columnColor: '#414141FF',
            color: 0,
            columnImage: './images/settings/11cm_sima.png',
            columnTop: null,
        },
        columns: Array.from({ length: 5 }, (_, index) => ({
            element: {
                width: 200,
                height: 0,
                // Add other properties as needed
            },
        })),
    },
    {
        name: "Hátul",
        image: './images/sample/Minta_haz_hatul.jpg',
        color: 0,
        planner: {
            width: 1115,
            height: 200,
            columnWidth: 23,
            columnColor: '#414141FF',
            color: 0,
            columnImage: './images/settings/11cm_sima.png',
            columnTop: null,
        },
        columns: Array.from({ length: 5 }, (_, index) => ({
            element: {
                width: 200,
                height: 0,
                // Add other properties as needed
            },
        })),
    },
    {
        name: "Bal oldal",
        image: './images/sample/Minta_haz_bal.jpg',
        color: 0,
        planner: {
            width: 1115,
            height: 200,
            columnWidth: 23,
            columnColor: '#414141FF',
            color: 0,
            columnImage: './images/settings/11cm_sima.png',
            columnTop: null,
        },
        columns: Array.from({ length: 5 }, (_, index) => ({
            element: {
                width: 200,
                height: 0,
                // Add other properties as needed
            },
        })),
    },
];

function App() {
    const { sessionData, setSessionData, isOnPath } = useSessionData();
    const [dataArrays, setDataArrays] = useState(initialDataArray);
    const [selectedDataIndex, setSelectedDataIndex] = useState(0);
    const [data, setData] = useState(dataArrays[selectedDataIndex])

    useEffect(() => {
        getSessionData();
    }, []);

    useEffect(() => {
        setData(dataArrays[selectedDataIndex]);
    }, [selectedDataIndex]);

    useEffect(() => {
        // Update dataArrays when data changes
        const updatedArrays = [...dataArrays];
        updatedArrays[selectedDataIndex] = { ...data };
        setDataArrays(updatedArrays);
    }, [data]);

    function getSessionData() {
        axios.get('/api/user/session')
            .then((result) => {
                console.log(result);
                if (result.data !== false) {
                    setSessionData({
                        ...sessionData,
                        userId: result.data.user_id,
                        rights: result.data.rights,
                        logged: result.data.logged,
                        username: result.data.username,
                        email: result.data.email,
                        isUserAdmin: result.data.rights === 5,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleDataSelect = (index) => {
        setSelectedDataIndex(index);
    };

    const saveData = () => {

    }

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="App">
                <BrowserRouter>
                    <Header />
                    <Grid container>
                        {!isOnPath('/orders') &&
                        <Grid item xs={4}>
                            {dataArrays.map((item, index) => (
                                <Button variant={selectedDataIndex === index ? "contained" : "outlined"} key={index} sx={{ ml: 2}} onClick={() => handleDataSelect(index)}>
                                    {item.name}
                                </Button>
                            ))}
                            <Sidebar data={data} setData={setData} dataArrays={dataArrays} saveData={saveData} />
                        </Grid>}
                        <Grid item xs={isOnPath('/orders') ? 12 : 8}>
                            <Routes>
                                <Route index path="/" element={<Planner data={data} setData={setData} />} />
                            </Routes>
                            <RequireAdmin>
                                <Routes>
                                    <Route path="/addElement" element={<CreateElement setData={setData} />} />
                                    <Route path="/addColumn" element={<CreateColumn setData={setData} />} />
                                    <Route path="/elements" element={<ListElements />} />
                                    <Route path="/addColor" element={<CreateColor setData={setData} />} />
                                    <Route path="/orders" element={<ListOrders dataArrays={dataArrays} setDataArrays={setDataArrays} setData={setData} />} />
                                </Routes>
                            </RequireAdmin>
                        </Grid>
                    </Grid>
                </BrowserRouter>
            </div>
        </DndProvider>
    );
}

export default App;
