import axios from "axios"
import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import {Link, useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";

export default function ListOrders({ dataArrays, setDataArrays, setData })
    {
        const [orderData, setOrderData] = useState([]);
        const navigate = useNavigate();

        const columns = [
            { field: 'id', headerName: 'ID', width: 20},
            { field: 'name', headerName: 'Név', width: 150},
            { field: 'email', headerName: 'E-mail', width: 150},
            { field: 'telephone', headerName: 'Telefonszám', width: 150},
            { field: 'city', headerName: 'Város', width: 100},
            { field: 'level', headerName: 'Szintkülönbség', width: 150},
            { field: 'destroy', headerName: 'Kerítés elbontása', width: 150},
            { field: 'quality', headerName: 'Talaj minősége', width: 150},
            { field: 'finding', headerName: 'Hol talált ránk', width: 150},
            { field: 'comment', headerName: 'Megjegyzés', width: 200},
            {
                field: 'open',
                headerName: 'Megnyitás',
                width: 200,
                renderCell: (params) => (
                    <>
                        <Button
                            onClick={() => handleOpenRowData(params.row.data)}
                            style={{ marginRight: "10px" }}
                        >
                            Megnyitás
                        </Button>
                    </>
                ),
            },
            {
                field: 'actions',
                headerName: 'Törlés',
                width: 150,
                renderCell: (params) => (
                    <>
                        <Button
                            onClick={() => deleteRow(params.row.id)}
                            style={{ marginRight: "10px" }}
                            variant="contained"
                            color="error"
                        >
                            Törlés
                        </Button>
                    </>
                ),
            },
        ];

        const handleOpenRowData = (rowData) => {
            const decodedData = JSON.parse(rowData);

            setDataArrays(decodedData);
            setData(decodedData[0]);

            navigate("/");
        };

        const deleteRow = (rowid) => {
            console.log(rowid);
            const formData = new FormData();
            formData.append('id', rowid);
            axios.post('/api/delete/order', formData).then(function (response) {
                getMasterPrompts();
            });
        };

        useEffect(() => {
            getMasterPrompts();
        }, []);

        function getMasterPrompts()
        {
            axios.get('/api/list/orders').then(function (response) {
                setOrderData(response.data);
            });
        }

        return (
        <div style={{ height: '100%', width: '100%' }}>
            <DataGrid
                rows={orderData}
                columns={columns}
                editMode="cell"
                pageSizeOptions={[5, 10, 100]}
            />
        </div>
        )
    }
