import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import axios from "axios";
import { useDrag } from "react-dnd";
import {Divider, ListItemIcon, ListSubheader, MenuItem, Paper, Select} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TextField from "@mui/material/TextField";
import {styled} from "@mui/system";
import IconButton from "@mui/material/IconButton";
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';

const RoundedIconButton = styled(IconButton)({
    borderRadius: "50%",
});

export default function Sidebar({ data, setData, dataArrays, saveData }) {
    const [selectedGroup, setSelectedGroup] = useState(6);
    const [elements, setElements] = useState([]);
    const [colors, setColors] = useState([]);
    const [columns, setColumns] = useState([]);
    const [activeTab, setActiveTab] = React.useState('1');
    const [sent, setSent] = useState(false);

    const DraggableElement = ({ element }) => {
        const [{ isDragging }, drag] = useDrag({
            type: 'element', // Set a string value for your drag type
            item: { type: 'element', element }, // Also define type in the item object
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            }),
        });

        return (
            <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1, cursor: "pointer" }}>
                <Grid container sx={{ mb: 2, border: "2px solid #000", borderRadius: "7px", p: 1, backgroundColor: "#fff" }}>
                    <Grid item xs={3}>
                        <h5>{element.name}</h5>
                    </Grid>
                    <Grid item xs={9}>
                        <img src={element.image} alt={element.name} width={element.type === 4 ? "50%" : "100%"} style={{ maxHeight: "100px" }} />
                    </Grid>
                </Grid>
            </div>
        );
    };

    useEffect(() => {
        getElements();
        getColors();
        getColumns();
    }, []);

    function getElements() {
        axios.get('/api/get/elements')
            .then((result) => {
                if (result.data !== false) {
                    setElements(result.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function getColors() {
        axios.get('/api/get/colors')
            .then((result) => {
                if (result.data !== false) {
                    setColors(result.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function getColumns() {
        axios.get('/api/get/columns')
            .then((result) => {
                if (result.data !== false) {
                    setColumns(result.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        saveData();
        const newDataArrays = dataArrays.map((item) => ({ ...item }));
        const formData = new FormData();
        for (const [key, value] of Object.entries(data)) {
            formData.append(key, value);
        }
        const dataJson = JSON.stringify(newDataArrays);
        formData.append('data', dataJson);
        console.log(formData);
        axios.post('/api/create/order', formData)
            .then(function (response) {
                console.log(response);
                if (response.data === 1) {
                    setSent(true);
                }
            });
    };

    function handleGroupClick(group) {
        setSelectedGroup(group);
    }

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        if (newValue === '2') {
            setSelectedGroup(6);
        }
        if (newValue === '3') {
            setSelectedGroup(4);
            const newData = { ...data };
            newData.color = 0;
            setData(newData);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        const newData = { ...data }; // Create a copy of the data object

        // Modify the data object based on the name of the Select component
        if (name === 'columnWidth') {
            newData.planner.columnWidth = value;
            newData.planner.width = (value * 5) + 1000;
        } else if (name === 'height') {
            newData.planner.height = value;
        } else if (name === 'columnColor') {
            newData.planner.columnColor = value;
        } else {
            newData[name] = value;
        }

        // Update the state with the modified data object
        setData(newData);
    };

    const handleColumnImageChange = (newImage) => {
        const newData = { ...data };
        newData.planner.columnImage = newImage;
        setData(newData);
    }

    const handleColumnTopChange = (newImage) => {
        const newData = { ...data };
        newData.planner.columnTop = newImage;
        setData(newData);
    }

    const filteredElements = elements.filter(element => element.type === selectedGroup && element.color === data.color);
    const filteredColumns = columns.filter(column => column.color === data.color && column.width === data.planner.columnWidth && column.height === data.planner.height);
    console.log(filteredColumns);

    return (
        <Grid container sx={{ p: 2 }}>
            <Paper elevation={3} sx={{ p: 2, backgroundColor: "#e8e8e8", width: "700px" }}>
                <TabContext value={activeTab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleTabChange} aria-label="plan tabs">
                            <Tab label="Oszlopok" value="1" />
                            <Tab label="Kerítés Elemek" value="2" />
                            <Tab label="Kapuk" value="3" />
                            {/*<Tab label="Díszítő elemek" value="4" />*/}
                            <Tab label="Árajánlat" value="5" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <Grid item xs={12} >
                            <Grid container spacing={2} width={"100%"}>
                                <Grid item xs={6}>
                                    <InputLabel id="column-width-label" sx={{ mt: 3 }}>Válasszon oszlop típust</InputLabel>
                                    <Select
                                        id="column-width"
                                        name="columnWidth"
                                        labelId="column-width-label"
                                        value={data.planner.columnWidth}
                                        onChange={handleChange}
                                        fullWidth
                                        label="Válasszon oszlop típust"
                                    >
                                        <MenuItem value={11}> Normál oszlop - 11 cm széles</MenuItem>
                                        <MenuItem value={13}> Mintás oszlop - 13 cm széles </MenuItem>
                                        <MenuItem value={23}> Mintás széles oszlop - 23 cm </MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel id="column-height-label" sx={{ mt: 3 }}>Válassza ki a kerítés magassága</InputLabel>
                                    {data.planner.columnWidth === 11 ? (
                                        <>
                                            <Select
                                                id="height"
                                                name="height"
                                                labelId="column-height-label"
                                                value={data.planner.height}
                                                onChange={handleChange}
                                                fullWidth
                                                label="Válassza ki a kerítés magassága"
                                            >
                                                <MenuItem value={150}> 1,5 méter </MenuItem>
                                                <MenuItem value={175}> 1,75 méter </MenuItem>
                                                <MenuItem value={200}> 2 méter </MenuItem>
                                                <MenuItem value={225}> 2,25 méter </MenuItem>
                                                <MenuItem value={250}> 2,5 méter </MenuItem>
                                                <MenuItem value={300}> 3 méter </MenuItem>
                                            </Select>
                                        </>
                                    ) : (
                                        <>
                                            <Select
                                                id="height"
                                                name="height"
                                                labelId="column-height-label"
                                                value={data.planner.height}
                                                onChange={handleChange}
                                                fullWidth
                                                label="Válassza ki a kerítés magassága"
                                            >
                                                <MenuItem value={150}> 1,5 méter </MenuItem>
                                                <MenuItem value={175}> 1,75 méter </MenuItem>
                                                <MenuItem value={200}> 2 méter </MenuItem>
                                            </Select>
                                        </>
                                    )}
                                </Grid>
                                <Grid item xs={8}>
                                    <h4>Választható oszlop minták</h4>
                                    {filteredColumns.map((column, index) => (
                                        <RoundedIconButton key={index} onClick={() => handleColumnImageChange(column.image)}>
                                            <img
                                                src={column.image}
                                                width="35"
                                                height="35"
                                                style={{ borderRadius: "50%",
                                                    border: `3px solid #fff`,
                                                }}
                                            />
                                        </RoundedIconButton>
                                    ))}
                                </Grid>
                                <Grid item xs={4}>
                                    <Select
                                        id="color"
                                        name="color"
                                        value={data.color}
                                        labelId="color-label"
                                        onChange={handleChange}
                                        fullWidth
                                    >
                                        <MenuItem key={0} value={0}>
                                            <ListItemIcon>
                                                <div
                                                    style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        backgroundColor: "#414141FF",
                                                        marginRight: '2px',
                                                        border: '1px solid #ccc',
                                                        borderRadius: '50%',
                                                    }}
                                                />
                                            </ListItemIcon>
                                            Natúr betonszín
                                        </MenuItem>
                                        <ListSubheader key={0} disableSticky>
                                            Anyagában színezett
                                        </ListSubheader>
                                        {colors.map((color, index) => (
                                            color.type === 1 &&
                                            <MenuItem key={index} value={color.id}>
                                                <ListItemIcon>
                                                    <div
                                                        style={{
                                                            width: '20px',
                                                            height: '20px',
                                                            backgroundColor: color.color,
                                                            marginRight: '2px',
                                                            border: '1px solid #ccc',
                                                            borderRadius: '50%',
                                                        }}
                                                    />
                                                </ListItemIcon>
                                                {color.name}
                                            </MenuItem>
                                        ))}
                                        <ListSubheader key={1} disableSticky>
                                            Festéssel rendelhető színek
                                        </ListSubheader>
                                        {colors.map((color, index) => (
                                            color.type === 2 &&
                                            <MenuItem key={index} value={color.id}>
                                                <ListItemIcon>
                                                    <div
                                                        style={{
                                                            width: '20px',
                                                            height: '20px',
                                                            backgroundColor: color.color,
                                                            marginRight: '2px',
                                                            border: '1px solid #ccc',
                                                            borderRadius: '50%',
                                                        }}
                                                    />
                                                </ListItemIcon>
                                                {color.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={12}>
                                    <h4>Választható oszlopdíszek</h4>
                                    <RoundedIconButton onClick={() => handleColumnTopChange(null)}>
                                        <DoNotDisturbIcon />
                                    </RoundedIconButton>
                                {data.planner.columnWidth === 23 && (
                                    <>
                                        <RoundedIconButton onClick={() => handleColumnTopChange('./images/settings/oszlopdisz_01_gomb.png')}>
                                            <img
                                                src={'./images/settings/oszlopdisz_01_gomb.png'}
                                                width="35"
                                                height="35"
                                                style={{ borderRadius: "50%",
                                                    border: `3px solid #fff`,
                                                }}
                                            />
                                        </RoundedIconButton>
                                        <RoundedIconButton onClick={() => handleColumnTopChange('./images/settings/oszlopdisz_03_kalap.png')}>
                                            <img
                                                src={'./images/settings/oszlopdisz_03_kalap.png'}
                                                width="35"
                                                height="35"
                                                style={{ borderRadius: "50%",
                                                    border: `3px solid #fff`,
                                                }}
                                            />
                                        </RoundedIconButton>
                                    </>
                                )}
                                </Grid>
                                {/*<Grid item xs={12}>*/}
                                {/*    <InputLabel id="columnColor-label" sx={{ mt: 3 }}>Oszlop szín</InputLabel>*/}
                                {/*    <Select*/}
                                {/*        id="columnColor"*/}
                                {/*        name="columnColor"*/}
                                {/*        labelId="fenceColor-label"*/}
                                {/*        value={data.planner.columnColor}*/}
                                {/*        onChange={handleChange}*/}
                                {/*        fullWidth*/}
                                {/*        label="Oszlop szín"*/}
                                {/*    >*/}
                                {/*        <MenuItem key={0} value={"#414141FF"}>*/}
                                {/*            <ListItemIcon>*/}
                                {/*                <div*/}
                                {/*                    style={{*/}
                                {/*                        width: '20px',*/}
                                {/*                        height: '20px',*/}
                                {/*                        backgroundColor: "#414141FF",*/}
                                {/*                        marginRight: '2px',*/}
                                {/*                        border: '1px solid #ccc',*/}
                                {/*                        borderRadius: '50%',*/}
                                {/*                    }}*/}
                                {/*                />*/}
                                {/*            </ListItemIcon>*/}
                                {/*            Nincs festve*/}
                                {/*        </MenuItem>*/}
                                {/*        <ListSubheader key={0} disableSticky>*/}
                                {/*            Anyagában színezett*/}
                                {/*        </ListSubheader>*/}
                                {/*        {colors.map((color, index) => (*/}
                                {/*            color.type === 1 &&*/}
                                {/*            <MenuItem key={index} value={color.color}>*/}
                                {/*                <ListItemIcon>*/}
                                {/*                    <div*/}
                                {/*                        style={{*/}
                                {/*                            width: '20px',*/}
                                {/*                            height: '20px',*/}
                                {/*                            backgroundColor: color.color,*/}
                                {/*                            marginRight: '2px',*/}
                                {/*                            border: '1px solid #ccc',*/}
                                {/*                            borderRadius: '50%',*/}
                                {/*                        }}*/}
                                {/*                    />*/}
                                {/*                </ListItemIcon>*/}
                                {/*                {color.name}*/}
                                {/*            </MenuItem>*/}
                                {/*        ))}*/}
                                {/*        <ListSubheader key={1} disableSticky>*/}
                                {/*            Utólag festett*/}
                                {/*        </ListSubheader>*/}
                                {/*        {colors.map((color, index) => (*/}
                                {/*            color.type === 2 &&*/}
                                {/*            <MenuItem key={index} value={color.color}>*/}
                                {/*                <ListItemIcon>*/}
                                {/*                    <div*/}
                                {/*                        style={{*/}
                                {/*                            width: '20px',*/}
                                {/*                            height: '20px',*/}
                                {/*                            backgroundColor: color.color,*/}
                                {/*                            marginRight: '2px',*/}
                                {/*                            border: '1px solid #ccc',*/}
                                {/*                            borderRadius: '50%',*/}
                                {/*                        }}*/}
                                {/*                    />*/}
                                {/*                </ListItemIcon>*/}
                                {/*                {color.name}*/}
                                {/*            </MenuItem>*/}
                                {/*        ))}*/}
                                {/*    </Select>*/}
                                {/*</Grid>*/}
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value="2">
                        <Grid item xs={12}>
                            <Grid container width={"100%"}>
                                <Grid item xs={8}>
                                    <Button variant={selectedGroup === 6 ? "contained" : "outlined"} color="primary" onClick={() => handleGroupClick(6)} sx={{ m: 1 }}>
                                        200 cm
                                    </Button>
                                    <Button variant={selectedGroup === 1 ? "contained" : "outlined"} color="primary" onClick={() => handleGroupClick(1)} sx={{ m: 1 }}>
                                        205 cm
                                    </Button>
                                    <Button variant={selectedGroup === 2 ? "contained" : "outlined"} color="primary" onClick={() => handleGroupClick(2)} sx={{ m: 1 }}>
                                        213 cm
                                    </Button>
                                    <Button variant={selectedGroup === 3 ? "contained" : "outlined"} color="primary" onClick={() => handleGroupClick(3)} sx={{ m: 1 }}>
                                        215 cm
                                    </Button>
                                </Grid>
                                <Grid item xs={4}>
                                    <Select
                                        id="color"
                                        name="color"
                                        value={data.color}
                                        labelId="color-label"
                                        onChange={handleChange}
                                        fullWidth
                                    >
                                        <MenuItem key={0} value={0}>
                                            <ListItemIcon>
                                                <div
                                                    style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        backgroundColor: "#414141FF",
                                                        marginRight: '2px',
                                                        border: '1px solid #ccc',
                                                        borderRadius: '50%',
                                                    }}
                                                />
                                            </ListItemIcon>
                                            Natúr betonszín
                                        </MenuItem>
                                        <ListSubheader key={0} disableSticky>
                                            Anyagában színezett
                                        </ListSubheader>
                                        {colors.map((color, index) => (
                                            color.type === 1 &&
                                            <MenuItem key={index} value={color.id}>
                                                <ListItemIcon>
                                                    <div
                                                        style={{
                                                            width: '20px',
                                                            height: '20px',
                                                            backgroundColor: color.color,
                                                            marginRight: '2px',
                                                            border: '1px solid #ccc',
                                                            borderRadius: '50%',
                                                        }}
                                                    />
                                                </ListItemIcon>
                                                {color.name}
                                            </MenuItem>
                                        ))}
                                        <ListSubheader key={1} disableSticky>
                                            Festéssel rendelhető színek
                                        </ListSubheader>
                                        {colors.map((color, index) => (
                                            color.type === 2 &&
                                            <MenuItem key={index} value={color.id}>
                                                <ListItemIcon>
                                                    <div
                                                        style={{
                                                            width: '20px',
                                                            height: '20px',
                                                            backgroundColor: color.color,
                                                            marginRight: '2px',
                                                            border: '1px solid #ccc',
                                                            borderRadius: '50%',
                                                        }}
                                                    />
                                                </ListItemIcon>
                                                {color.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            </Grid>
                            <Divider sx={{ m: 1 }}/>
                            <Grid item xs={12} height={"800px"} sx={{ p: 2, overflow: "auto" }}>
                                {filteredElements.map((element, index) => (
                                    <DraggableElement key={index} element={element} />
                                ))}
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value="3">
                        <Grid item xs={12}>
                            <Grid container width={"100%"}>
                                <Grid item xs={8}>
                                    <Button variant={selectedGroup === 4 ? "contained" : "outlined"} color="primary" onClick={() => handleGroupClick(4)} sx={{ m: 1 }}>
                                        Kiskapu
                                    </Button>
                                    <Button variant={selectedGroup === 5 ? "contained" : "outlined"} color="primary" onClick={() => handleGroupClick(5)} sx={{ m: 1 }}>
                                        Nagykapu
                                    </Button>
                                </Grid>
                            </Grid>
                            <Divider sx={{ m: 1 }}/>
                            <Grid item xs={12} height={"800px"} sx={{ p: 2, overflow: "auto" }}>
                                {filteredElements.map((element, index) => (
                                    <DraggableElement key={index} element={element} />
                                ))}
                            </Grid>
                        </Grid>
                    </TabPanel>
                    {/*<TabPanel value="4">*/}
                    {/*    <Grid item xs={12}>*/}
                    {/*    </Grid>*/}
                    {/*</TabPanel>*/}
                    <TabPanel value="5">
                        {sent === false ? (
                        <form onSubmit={handleSubmit}>
                        <Grid item xs={12}>
                            <Grid container item xs={12} spacing={1}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="username"
                                        label="Név:"
                                        name="username"
                                        value={data.username || ''}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="email"
                                        label="Email cím:"
                                        name="email"
                                        value={data.email || ''}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="telephone"
                                        label="Telefonszám:"
                                        name="telephone"
                                        value={data.telephone || ''}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="city"
                                        label="Város:"
                                        name="city"
                                        value={data.city || ''}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="level"
                                        label="Talaj szintkülönbsége:"
                                        name="level"
                                        value={data.level || ''}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel id="destroy-label" >Létező kerítés elbontása</InputLabel>
                                    <Select
                                        id="destroy"
                                        name="destroy"
                                        labelId="destroy-label"
                                        value={data.destroy || ''}
                                        onChange={handleChange}
                                        fullWidth
                                        label="Létező kerítés elbontása"
                                    >
                                        <MenuItem value={'Igen, szeretném elbontani'}>Igen, szeretném elbontani</MenuItem>
                                        <MenuItem value={'Nincs kerítésem'}>Nincs kerítésem</MenuItem>
                                        <MenuItem value={'Hozzáépíteni szeretnék'}>Hozzáépíteni szeretnék</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel id="quality-label" >Talaj minősége</InputLabel>
                                    <Select
                                        id="quality"
                                        name="quality"
                                        labelId="quality-label"
                                        value={data.quality || ''}
                                        onChange={handleChange}
                                        fullWidth
                                        label="Talaj minősége"
                                    >
                                        <MenuItem value={'Homokos - könnyű'}>Homokos - könnyű</MenuItem>
                                        <MenuItem value={'Iszapos'}>Iszapos</MenuItem>
                                        <MenuItem value={'Agyagos'}>Agyagos</MenuItem>
                                        <MenuItem value={'Normál földes'}>Normál földes</MenuItem>
                                        <MenuItem value={'Kavicsos'}>Kavicsos</MenuItem>
                                        <MenuItem value={'Sziklás'}>Sziklás</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel id="finding-label" >Hol talált ránk?</InputLabel>
                                    <Select
                                        id="finding"
                                        name="finding"
                                        labelId="finding-label"
                                        value={data.finding || ''}
                                        onChange={handleChange}
                                        fullWidth
                                        label="Hol talált ránk?"
                                        required
                                    >
                                        <MenuItem value={'Google keresőből'}>Google keresőből</MenuItem>
                                        <MenuItem value={'Facebook hirdetésből'}>Facebook hirdetésből</MenuItem>
                                        <MenuItem value={'Instagram'}>Instagram</MenuItem>
                                        <MenuItem value={'Youtube'}>Youtube</MenuItem>
                                        <MenuItem value={'Tik-Tok'}>Tik-Tok</MenuItem>
                                        <MenuItem value={'Útmenti mintahelyen láttam'}>Útmenti mintahelyen láttam</MenuItem>
                                        <MenuItem value={'Ajánlás útján'}>Ajánlás útján</MenuItem>
                                        <MenuItem value={'Egyéb helyről'}>Egyéb helyről</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={12}>
                                    <h4>Méretek:</h4>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        id="frontLength"
                                        label="Kerítés hossza elöl (méter)"
                                        name="frontLength"
                                        value={data.frontLength || 0}
                                        type="number"
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        id="rightLength"
                                        label="Kerítés hossza jobb oldalt (méter)"
                                        name="rightLength"
                                        value={data.rightLength || 0}
                                        type="number"
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        id="backLength"
                                        label="Kerítés hossza hátul (méter)"
                                        name="backLength"
                                        value={data.backLength || 0}
                                        type="number"
                                        onChange={handleChange}
                                        sx={{ mt: 3 }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        id="leftLength"
                                        label="Kerítés hossza bal oldalt (méter)"
                                        name="leftLength"
                                        value={data.leftLength || 0}
                                        type="number"
                                        onChange={handleChange}
                                        sx={{ mt: 3 }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="comment"
                                        label="Megjegyzés"
                                        name="comment"
                                        value={data.comment || ''}
                                        onChange={handleChange}
                                        sx={{ mt: 3 }}
                                    />
                                </Grid>
                                <Divider sx={{ m: 3 }}/>
                                <Button variant="contained" color="success" fullWidth sx={{ mb: 1 }} type={"submit"} > Ajánlatkérés </Button>
                            </Grid>
                        </Grid>
                        </form>
                        ) : (
                            <Grid item xs={12}>
                                <h3>Köszönjük az érdeklődését!</h3>
                                <p>Árajánlat kérését sikeresen elküldtük!</p>
                            </Grid>
                        )}
                    </TabPanel>
                </TabContext>
            </Paper>
        </Grid>
    );
}
